import React from 'react';
import { CommonHelmet } from 'common';
import PropTypes from 'prop-types';

const DashboardPage = ({ t }) => {
  return (
    <>
      <CommonHelmet t={t} title={t('page.dashboard_page')} />
      <h1>{t('page.dashboard_page')}</h1>
    </>
  );
};
DashboardPage.propTypes = {
  t: PropTypes.func,
};
export { DashboardPage };
