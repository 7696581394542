import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { settingData } from 'store';

const CommonHelmet = ({ t, title }) => {
  const getSettingData = useSelector(settingData);
  const titles =
    getSettingData.home_page_general_seo_title + ' | ' + title ||
    t('page.home_page_general_seo_title');

  return (
    <Helmet>
      <title>{titles}</title>
      <meta name="og:title" content={titles} />
      <meta name="title" content={titles} />
      <meta name="description" content={getSettingData.home_page_general_seo_description} />
      <meta name="og:description" content={getSettingData.home_page_general_seo_description} />
      {getSettingData.home_page_general_favicon_logo && (
        <link rel="icon" href={getSettingData.home_page_general_favicon_logo} />
      )}
    </Helmet>
  );
};
CommonHelmet.propTypes = {
  t: PropTypes.func,
  title: PropTypes.any,
};
export default CommonHelmet;
